(() ->

  getWindowHeight = ->
    return $(window).height()

  getViewportHeight = ->
    return Math.max document.documentElement.clientHeight, (window.innerHeight || 0)

  h = getViewportHeight()
  $drainBack = $('#sticky-drain-back')
  $drainFront = $('#sticky-drain-front')
  $footer = $('#footer')
  footerPosition = $footer.offset()

  getFooterTop = ->
    return footerPosition.top

  getTopSectionHeight = ->
    return ( h + h / 2 ) + 1

  getWindowBottom = ->
    return $(document).scrollTop() + h

  calcPypthon = ->
    if getWindowBottom() < getTopSectionHeight()
      $drainBack.removeClass 'fixed'
      $drainFront.removeClass 'fixed'
      $drainFront.css 'top', getTopSectionHeight() - 50
      $drainBack.css 'top', getTopSectionHeight() - 50
    else if getWindowBottom() > ( getFooterTop() + 50 )
#      $drainBack.addClass 'bind-to-bottom'
#      $drainFront.addClass 'bind-to-bottom'
      $drainBack.removeClass 'fixed'
      $drainFront.removeClass 'fixed'
      $drainFront.css 'bottom', getFooterTop() + 50
      $drainBack.css 'bottom', getFooterTop() + 50
    else
      $drainBack.addClass('fixed').removeAttr('style')
      $drainFront.addClass('fixed').removeAttr('style')

  calcPypthon()

  animateIn = ->
    $('.animate-from-here').each ( i ) ->
      $that = $(this)
      $animated = $that.find('.animated')
      thisTop = $that.offset().top
      thisHeight = $that.height()
      midSection = thisTop + thisHeight / 2

      if getWindowBottom() > midSection
        $animated.addClass 'zoomInUp'

  $(document).scroll ->
    calcPypthon()
    animateIn()

  $(window).resize ->
    footerPosition = $footer.offset()
    h = getViewportHeight()
    calcPypthon()
    animateIn()

) jQuery