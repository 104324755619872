(() ->

  getWindowHeight = ->
    return $(window).height()

  # center up
  centerUp = ->
    $('.center-up').each ->
      $that = $(this)
      thisHeight = $that.height()
      $that.css 'marginTop', ( thisHeight / -2 ) + 'px'

  setHieght = (height) ->
    $('.window-height').css 'height', height

  # setup page
  setupPage = ->
    h = getWindowHeight()
    setHieght h
    $pypthon = $('#pypthon')
    $pypthon.css 'height', ( $('body').height() - 790 - h * 2 )
    $pypthon.css 'top', ( h + ( h / 2 ) + 130 )
    centerUp()

  setupPage()
  $(".video").fitVids()

  # setup page when resizing
  $(window).resize ->
    setupPage()

  $('.scroll-to').on 'click', (e) ->
    e.preventDefault()
    $that = $(this)
    speed = $(this).data 'speed'

    location =
      scrollTop: $( $that.attr('href') ).offset().top - 80

    $('html, body').animate location , speed

  # zoom
  funcZoom = (item) ->
    return item.el.attr 'title'

  funcGallery = (element) ->
    return element.find 'img'

  paramZoom =
    delegate: 'a'
    type: 'image'
    closeOnContentClick: false
    closeBtnInside: false
    mainClass: 'mfp-with-zoom mfp-img-mobile'
    image:
      verticalFit: true,
      titleSrc: funcZoom
    gallery:
      enabled: true
    zoom:
      enabled: true,
      duration: 300,
      opener: funcGallery

  $('.gallery').magnificPopup paramZoom

) jQuery